:root {
    --color-white: #fff;
    --color-black: #000000;


    font-family: 'Comfortaa', sans-serif;
    font-weight: 400;
    text-align: left;
    color: var(--color-white);

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;


    --text-stroke: 1px var(--color-black);

    --h1-size: 24px;
    --h2-size: 16px;

    --p-size: 16px;


    --h1-lh: 48px;
    --h2-lh: 28px;

    --p-lh: 24px;


    --h1-mb: 40px;
    --h2-mb: 24px;
    --p-mb: 80px;

    --btn-size: 12px;
    --btn-lh: 16px;
    --btn-padding: 18px 48px 16px 48px;


    /* ---------------------------------- PEPE ---------------------------------- */

    --color-green: #4B9540;
    --color-yellow: #ffd203;

    --arrow-width: 56px;
    --arrow-height: 48px;

    --padding: 64px 24px 70px 24px;
    --button-border: 1px solid var(--color-black);
}


@media screen and (min-width:660px) {
    :root {
        --h1-size: 40px;
        --h2-size: 32px;

        --p-size: 24px;


        --h1-lh: 80px;
        --h2-lh: 48px;

        --p-lh: 40px;

        --h1-mb: 80px;
        --h2-mb: 48px;
        --p-mb: 120px;

        --btn-size: 16px;
        --btn-lh: 24px;
        --btn-padding: 18px 48px 16px 48px;

        --arrow-width: 83px;
        --arrow-height: 72px;

        --padding: 186px 40px;

        --button-border: 2px solid var(--color-black);

    }
}


@media screen and (min-width:1024px) {
    :root {
        --h1-size: 48px;


        --h1-lh: 96px;

        --p-mb: 80px;

        --text-stroke: 2px var(--color-black);

        --padding: 80px;
    }
}