.info-section {

    --headerWidth: 664px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    min-height: 100vh;
    position: relative;
    /* background: var(--color-green); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: hidden;
    padding: var(--padding);

    .info-section__body {
        position: relative;
        max-width: 1040px;
        width: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .header-container {
            width: 100%;
            margin-bottom: 80px;

            .pepe-image {
                position: absolute;
                right: 0;
                top: -16px;
                z-index: -1;
            }

            .pepe-font {
                max-width: var(--headerWidth);
                margin-bottom: 0;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            max-width: var(--headerWidth);
        }

        .pepe-font {
            font-family: 'Evermore', sans-serif;
        }

        h2 {
            .pepe-font {
                font-size: calc(var(--h2-size) - 8px);
            }
        }

        .telegram-btn {
            position: relative;

            .arrow {
                width: var(--arrow-width);
                height: var(--arrow-height);
                position: absolute;
                right: calc(-1 * (var(--arrow-width) + 40px));
                bottom: 14px;
                transform: rotate(15deg);

            }
        }

    }



}



.background {
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

body {
    position: relative;
    background-color: var(--color-green);
}






@media screen and (max-width: 1023px) {
    .info-section {
        --headerWidth: 341px;

        .info-section__body {
            .text-container {
                max-width: none;
            }

            .info-link {
                align-self: center;
            }

            .telegram-btn {
                .arrow {
                    right: calc(-1 * (var(--arrow-width) + 32px));
                }
            }

        }



    }
}

@media screen and (max-width: 850px) {
    .info-section {

        .info-section__body {

            .header-container {
                .pepe-image {
                    width: 360px;
                    height: 260px;
                    position: absolute;
                    top: -10px;
                }
            }
        }
    }
}



@media screen and (max-width: 660px) {

    .info-section {
        --headerWidth: none;

        .info-section__body {
            align-items: center;

            .header-container {
                gap: 24px;
                flex-direction: column-reverse;
                align-items: center;
                display: flex;
                margin-bottom: 40px;

                .pepe-image {
                    position: relative;
                    width: 100%;
                    height: auto;
                    max-width: 500px;
                }
            }

            .text-container {
                margin-top: 0px;
            }

            h2 {
                .pepe-font {
                    font-size: calc(var(--h2-size) - 4px);
                }
            }


            .pepe-image {
                width: 200px;
                height: 200px;
            }

            .info-link {
                align-self: center;
            }

            .telegram-btn {
                .arrow {
                    right: calc(-1 * (var(--arrow-width) - 3px));
                    bottom: calc(var(--arrow-height));
                    transform: rotate(348deg);
                }
            }

        }



    }
}